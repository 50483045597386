import React from "react";
import { Link } from "gatsby";
import { Table, Avatar } from "antd";

import Api from "../components/api";
import Console from "../components/console";
import Seo from "../components/seo";

const columns = [
    {
        title: 'N° Compte',
        dataIndex: 'id',
        key: 'id',
        render: (text, record) => <Link to={`/account?id=${record.id}`}>{text}</Link>
    },
    {
        title: 'Détenteur',
        dataIndex: 'user',
        key: 'user',
        render: (text, record) => <Link to={`/account?id=${record.id}`}>{text}</Link>
    },
    {
        title: 'Type de compte',
        dataIndex: 'type',
        key: 'type',
        render: (text, record) => <Link to={`/account?id=${record.id}`}>{text}</Link>
    },
    {
        title: 'Solde',
        dataIndex: 'amount',
        key: 'amount',
        render: (text, record) => <Link to={`/account?id=${record.id}`}>{text.toLocaleString("fr", {minimumFractionDigits: 2})} €</Link>
    },
    {
        title: 'Attente de validation',
        dataIndex: 'validation',
        key: 'validation',
        render: (text, record) => text > 0 ? <Link to={`/account?id=${record.id}`}><Avatar style={{ color: '#FFFFFF', backgroundColor: '#CC0000' }}>{text}</Avatar></Link> : <React.Fragment />
    }
];

class Externals extends React.Component {

    state = {
        data: null
    }

    componentDidMount() {
        this.load();
    }

    load = () => {
        this.setState({data: null});
        Api("accounts_attached", {})
                .then(data => {
                    this.setState({data: data.accounts});
                });
    }

    render() {
        const {data} = this.state;
        return <Console menu="externals" help={["accounts_attached", "transaction_validation_notification"]} onChangeUser={this.load}>
            <Seo title="Comptes externes" />
            <h1>Liste des comptes qui vous sont rattachés</h1>
            <Table dataSource={data} loading={!data} columns={columns} />
        </Console>;
    }
};

export default Externals;
